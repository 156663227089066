import HttpClient from "axios-mapper";
import { ElMessage } from "element-plus";
import { useStore } from "@/store";
import { ResponseCode } from "../api/response";
import _cloneDeep from 'lodash/cloneDeep';
import { cacher } from "@/utils/cache";
import axios from 'axios'

const toRawType = (value) => {
  return Object.prototype.toString.call(value).slice(8, -1)
}

const clearQueryStr = (param) => {
  return param.split("&").filter(item => item.split("=")[1] !== '').join("&");
}

const clearEmptyParam = (config) => {
  ['data', 'params'].forEach(item => {
    if (config[item]) {
      const rawType = toRawType(config[item])
      if (['Object'].includes(rawType)) {
        const keys = Object.keys(config[item])
        if (keys.length) {
          keys.forEach(key => {
            if (['', undefined, null].includes(config[item][key])) {
              if (['Object'].includes(rawType)) {
                config[item] = _cloneDeep(config[item])
                delete config[item][key]
              }
            }
          })
        }
      } else if (['String'].includes(rawType)) {
        config[item] = clearQueryStr(config[item]);
      }
    }
  })
}

const cancelToken = axios.CancelToken

const getKey = (config) => {
  return config.url + JSON.stringify(config.params)
}

const onRequest = (config) => {
  clearEmptyParam(config);
  if (config.url.startsWith("/cache")) {
    config.url = config.url.substring("/cache".length)
    const data = cacher.get(getKey(config))
    if (data) {
      const source = cancelToken.source()
      config.cancelToken = source.token
      source.cancel(data)
    }
    config.cache = true
  }
  return config;
}

const onResponse = (response) => {
  let res = response.data;
  if (typeof response.data === "string") {
    res = JSON.parse(response.data);
  }
  if (res.code !== ResponseCode.SUCCESS) {
    if (res.code === ResponseCode.ERROR_UNAUTHORIZED) {
      console.log(res, ' there has been an err with the api unauthosized')
      location.reload();
    } else {
      ElMessage({
        message: res.message || "Error",
        type: "error"
      });
    }
    throw new Error(res.message || "Error");
  } else {
    if (response.config.cache) {
      cacher.set(getKey(response.config), response)
    }
    return response;
  }
};

const onResponseError = (error) => {
  if (axios.isCancel(error)) {
    return Promise.resolve(error.message)
  } else {
    ElMessage({
      message: error.message,
      type: "error"
    });
    return Promise.reject(error);
  }
};

const https = () => {
  const token = useStore().state.user.token;
  const config = {
    baseURL: process.env.VUE_APP_BASE_API,
    headers: {
      Authorization: `Bearer ${token}`
    },
    timeout: process.env.TIMEOUT,
  };
  const httpClient = new HttpClient(config);
  httpClient.httpClient.interceptors.request.use(onRequest);
  httpClient.httpClient.interceptors.response.use(onResponse, onResponseError);
  return httpClient;
};

export default https;
