<template>
  <div class="roles-main">
    <div class="header-container" />
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="450px"
    >
      <el-form
        ref="serverForm"
        :rules="formRules"
        :model="form"
        :inline="true"
        size="medium"
        label-width="150px"
      >
        <el-form-item label="Server" prop="serverCode">
          <el-input
            :disabled="true"
            v-model="form.code"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="Url Type">
          <el-radio-group v-model="form.urlType">
            <el-radio-button label="web">Web Url</el-radio-button>
            <el-radio-button label="app">App Url</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="New Outer URL" prop="url">
          <el-input v-model="form.url" maxlength="30" />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="editServerUrl">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-table
      :data="records.list"
      ref="table"
      row-key="id"
      size="mini"
    >
      <el-table-column prop="serverCode" label="Server" />
      <el-table-column prop="outerUrl" label="Outer Web URL" />
      <el-table-column prop="outerUrlApp" label="Outer App URL" />
      <el-table-column prop="status" label="Status">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 'RUNNING'" type="success" size="mini">{{ scope.row.status }}</el-tag>
          <el-tag v-if="scope.row.status === 'PAUSE'" type="warning" size="mini">{{ scope.row.status }}</el-tag>
          <el-tag v-if="scope.row.status === 'DOWN'" type="danger" size="mini">{{ scope.row.status }}</el-tag>
          <el-tag v-if="scope.row.status === null" type="info" size="mini">-</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="totalUsers" label="Total Users" />
      <el-table-column prop="totalGuests" label="Total Guests" />
      <el-table-column prop="totalPartners" label="Total Partners" />
      <el-table-column prop="startTime" label="Start Time" />
      <el-table-column prop="lastCheckTime" label="Last Check Time" />
      <el-table-column label="operate" width="200">
        <template #default="scope">
          <el-button
            size="mini"
            type="success"
            icon="el-icon/>"
            @click="showEditServerUrl(scope.row)"
          >
            Update URL
          </el-button>
          <el-button v-if="scope.row.status === 'RUNNING'"
                     size="mini"
                     type="danger"
                     icon="el-icon/>"
                     @click="updateServerPause(scope.row)"
          >
            Pause
          </el-button>
          <el-button v-if="scope.row.status === 'PAUSE'"
                     icon="el-icon/>"
                     size="mini"
                     type="success"
                     @click="updateServerRun(scope.row)"
          >
            Run
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, onBeforeUnmount, onBeforeMount, nextTick } from 'vue'
import { getRealTimeServerStats, runServer, pauseServer, updateOuterUrl, updateOuterUrlApp } from '../../../api/server'
import { ElMessage, ElMessageBox } from 'element-plus'
import { required } from '../../../utils/validate'

let timer = ref(null);
const serverForm = ref(null)

const form = reactive({
  id: null,
  code: null,
  urlType: "web",
  url: null,
})

const formRules = reactive({
  id: [required('Server id is required')],
  url: [required('URL is required')],
})

const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
})

const records = reactive({
  list: [],
})

async function loadServerStats() {
  const { data: ret } = await getRealTimeServerStats()
  records.list = ret
}

async function updateServerRun(server) {
  ElMessageBox.confirm(
    'Confirm that you want to run this server',
    {
      confirmButtonText: 'confirm',
      cancelButtonText: 'cancel',
      type: 'warning',
    }
  ).then(async () => {
    if (server) {
      await runServer(server.id)
    }
    await getRealTimeServerStats()
    ElMessage({ message: 'Run Server Success', type: 'success' })
  })
}

async function updateServerPause(server) {
  ElMessageBox.confirm(
    'Confirm that you want to pause this server',
    {
      confirmButtonText: 'confirm',
      cancelButtonText: 'cancel',
      type: 'warning',
    }
  ).then(async () => {
    if (server) {
      await pauseServer(server.id)
    }
    await getRealTimeServerStats()
    ElMessage({ message: 'Pause Server Success', type: 'success' })
  })
}

function showEditServerUrl(server) {
  uiControl.dialogTitle = 'Update Server Outer Url'
  uiControl.dialogVisible = true
  nextTick(() => {
    form.id = server.id
    form.code = server.serverCode
    form.url = server.serverUrl
  })
}

function editServerUrl() {
  serverForm.value.validate(async valid => {
    if (valid) {
      if (form.urlType === 'web') {
        await updateOuterUrl(form.id, form.url)
      } else {
        await updateOuterUrlApp(form.id, form.url)
      }
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Outer URL Success', type: 'success' })
    }
    getRealTimeServerStats()
  })
}

onMounted(async() => {
  await loadServerStats()
})

onBeforeUnmount(async () => {
  clearInterval(timer);
})

onBeforeMount(async () => {
  timer = setInterval(loadServerStats, 30000)
})

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 38px;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}

.icon-body {
  width: 100%;

  .headicon-box {
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    div {
      cursor: pointer;

    }
  }
}

.headicon-img {
  width: 60px;
  display: block;
}

:deep(.el-radio-button--small .el-radio-button__inner) {
  padding: 0;
}

:deep(.el-radio-button--small) {
  padding: 0;
  img{

    filter: grayscale(.7);
  }

  &.is-active {
    img {
      filter: grayscale(0);
    }
  }
}
</style>
