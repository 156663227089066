<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-date-picker
          v-model="startDate"
          type="date"
          placeholder="Start Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-date-picker
          style="margin-left: 20px"
          v-model="endDate"
          type="date"
          placeholder="End Date"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          size="small"
        />
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="getMemberLoginCounts()"
        >
          Search
        </el-button>
        <el-select
          clearable
          v-model="statisticsType"
          size="small"
          placeholder="user state"
          class="filter-item"
          style="width: 120px; margin-left: 20px"
          @change="getMemberLoginCounts()"
        >
          <el-option
            v-for="item in uiControl.statisticsType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="chart-time" v-if="list.records.length > 0">
      <Chart :options="options" />
    </div>
    <el-table
      :data="list.records"
      ref="table"
      row-key="id"
      size="small"
      highlight-current-row
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column label="Time">
        <template #default="scope">
          <span v-if="statisticsType === statsType.DAY">
            {{ convertDate(scope.row.recordTime) }}
          </span>
          <span
            v-else
            v-formatter="{
              data: scope.row.recordTime,
              formatter: 'YYYY/MM/DD HH:mm:ss',
              type: 'date',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column prop="loginCount" label="Logins" />
      <el-table-column prop="regCount" label="Registrations" />
    </el-table>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from '@vue/runtime-core'
import { getMemberLoginByTime, statsType } from '../../api/stats'
import { convertDate, convertDateTime } from '@/utils/util'
import Chart from '@/components/charts/Charts'
import 'echarts/lib/component/tooltip'
import { cellGrayWhite } from '@/utils/style'
import moment from 'moment';

const list = reactive({
  records: [],
})

const startDate = ref(convertDate(moment(new Date()).startOf('month')))
const endDate = ref(convertDate(new Date()))
const statisticsType = ref('DAY')

const uiControl = reactive({
  statisticsType: [
    {
      value: statsType.DAY,
      label: 'Day',
    },
    {
      value: statsType.HOUR,
      label: 'Hour',
    },
  ],
})

const options = reactive({
  series: [],
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    data: [
      'Active Members',
      'New Members'
    ],
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    axisPointer: {
      type: 'shadow',
    },
    data: [],
  },
  yAxis: [
    {
      type: 'value',
      name: 'Member Count',
      alignTicks: true,
    },
  ],
})

async function getMemberLoginCounts() {
  const { data: res } = await getMemberLoginByTime(
    startDate.value,
    endDate.value,
    statisticsType.value
  )
  list.records = res
  if (res.length > 0) {
    const categoryData = []
    const loginCountData = []
    const regData = []
    res.forEach((item, index) => {
      if (statisticsType.value === statsType.DAY) {
        categoryData.push(convertDate(item.recordTime))
      } else {
        categoryData.push(convertDateTime(item.recordTime))
      }
      loginCountData.push(item.loginCount)
      regData.push(item.regCount)
    })
    options.xAxis.data = categoryData
    options.series = [
      {
        name: 'Member Login Count',
        type: 'line',
        data: loginCountData,
      },
      {
        name: 'Member Reg Count',
        type: 'line',
        data: regData,
      },
    ]
  }
}

onMounted(async () => {
  await getMemberLoginCounts()
})
</script>

<style scoped>
.chart-time {
  /* width: 700px; */
  height: 400px;
  margin: auto;
}
</style>
