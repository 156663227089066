<template>
  <div class="tables-container-wrap1">
    <el-card class="info-card">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Account Information</span>
        </div>
      </template>
      <el-descriptions
        size="small"
        class="margin-top"
        :column="3"
        border
        v-loading="loading.accountInfo"
      >
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="user" style="height: 16px;width: 16px;" />
              Username
            </div>
          </template>
          <span v-if="memberDetail.loginName !== null">{{ memberDetail.loginName }}</span>
          <span v-if="memberDetail.loginName === null">-</span>
          <el-button type="info" size="mini" style="float: right;"
                     v-permission="['sys:member:login-name:update']"
                     @click="showDialog('UPDATE_LOGIN_NAME')"
          >
            Update Username
          </el-button>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="user" style="height: 16px;width: 16px;" />
              Nickname
            </div>
          </template>
          <span v-if="memberDetail.nickName !== null">{{ memberDetail.nickName }}</span>
          <span v-if="memberDetail.nickName === null">-</span>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="lock" style="height: 16px;width: 16px;" />
              Status
            </div>
          </template>
          <el-tag v-if="memberDetail.status === 'NORMAL'" size="mini" type="success">
            {{ memberDetail.status }}
          </el-tag>
          <el-tag v-if="memberDetail.status === 'FROZEN'" size="mini" type="danger">
            {{ memberDetail.status }}
          </el-tag>
          <el-tag v-if="memberDetail.status === null" size="mini" type="info">
            -
          </el-tag>
          <el-button v-if="memberDetail.status === 'NORMAL' && memberDetail.type === 'USER'" type="info" size="mini" style="float: right;"
                     v-permission="['sys:member:status:update']" @click="freeze(memberDetail.id)"
          >
            Freeze
          </el-button>
          <el-button v-if="memberDetail.status === 'FROZEN' && memberDetail.type === 'USER'" type="info" size="mini" style="float: right;"
                     v-permission="['sys:member:status:update']" @click="unfreeze(memberDetail.id)"
          >
            Open
          </el-button>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="international" style="height: 16px;width: 16px;" />
              Country
            </div>
          </template>
          <span v-if="memberDetail.countryCode !== null">{{ memberDetail.countryCode }}</span>
          <span v-if="memberDetail.countryCode === null">-</span>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="phone" style="height: 16px;width: 16px;" />
              Telephone
            </div>
          </template>
          <span v-if="memberDetail.telephone !== null">{{ memberDetail.telephone }}</span>
          <span v-if="memberDetail.telephone === null">-</span>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label-class-name="member-label" class-name="member-context">
          <template #label>
            <div>
              <svg-icon icon-class="password" style="height: 16px;width: 16px;" />
              Password
            </div>
          </template>
          <el-button type="info" size="mini" v-permission="['sys:member:password:update']"
                     @click="showDialog('UPDATE_PASSWORD')"
          >
            Update Password
          </el-button>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>

    <el-card class="info-card">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Login Information</span>
        </div>
      </template>
      <el-descriptions
        size="small"
        class="margin-top"
        :column="3"
        border
        v-loading="loading.loginInfo"
      >
        <el-descriptions-item label-align="left" label="Register Time" label-class-name="member-label"
                              class-name="member-context"
        >
          <span v-if="memberDetail.regTime !== null"
                v-formatter="{data: memberDetail.regTime,formatter: 'YYYY/MM/DD HH:mm:ss',type: 'date'}"
          />
          <span v-if="memberDetail.regTime === null">-</span>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label="Register IP" label-class-name="member-label"
                              class-name="member-context"
        >
          <span v-if="memberDetail.regIp !== null">
            {{ memberDetail.regIp }}
          </span>
          <span v-if="memberDetail.regIp === null">-</span>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label="Register Address" label-class-name="member-label"
                              class-name="member-context"
        >
          <span
            v-if="memberDetail.regAddress !== '-,-,-' && memberDetail.regAddress !== 'null,null,null' && memberDetail.regAddress !== null"
          >{{
            memberDetail.regAddress
          }}</span>
          <span
            v-if="memberDetail.regAddress === '-,-,-' || memberDetail.regAddress === 'null,null,null' || memberDetail.regAddress === null"
          >-</span>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label="Last Login Time" label-class-name="member-label"
                              class-name="member-context"
        >
          <span v-if="memberDetail.lastLoginTime !== null"
                v-formatter="{data: memberDetail.lastLoginTime,formatter: 'YYYY/MM/DD HH:mm:ss',type: 'date'}"
          />
          <span v-if="memberDetail.lastLoginTime === null">-</span>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label="Last Login IP" label-class-name="member-label"
                              class-name="member-context"
        >
          <span v-if="memberDetail.lastLoginIp !== null">
            {{ memberDetail.lastLoginIp }}
          </span>
          <span v-if="memberDetail.lastLoginIp === null">-</span>
        </el-descriptions-item>
        <el-descriptions-item label-align="left" label="Last Login Address" label-class-name="member-label"
                              class-name="member-context"
        >
          <span
            v-if="memberDetail.lastLoginAddress !== '-,-,-' && memberDetail.lastLoginAddress !== 'null,null,null' && memberDetail.lastLoginAddress !== null"
          >{{
            memberDetail.lastLoginAddress
          }}</span>
          <span
            v-if="memberDetail.lastLoginAddress === '-,-,-' || memberDetail.lastLoginAddress === 'null,null,null' || memberDetail.lastLoginAddress === null"
          >-</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>

    <el-card class="info-card">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Login Records</span>
          <el-date-picker
            v-model="loginRecordRequest.range"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            size="mini"
            type="daterange"
            start-placeholder="Start"
            end-placeholder="End"
            style="width: 380px; margin-left: 50px;"
            :shortcuts="shortcuts"
            :disabled-date="disabledDate"
            :editable="false"
            :clearable="false"
            :default-value="[defaultStartDate, defaultEndDate]"
            @change="loadMemberLoginRecords"
          />
        </div>
      </template>
      <el-table :data="loginRecordPage.records" ref="table"
                v-loading="loading.loginRecord"
                row-key="id"
                size="small"
                highlight-current-row
                style="width: 100%"
      >
        <el-table-column prop="loginTime" label="Login Time" :min-width="20" />
        <el-table-column prop="loginIp" label="Login IP" :min-width="20" />
        <el-table-column prop="loginAddress" label="Address" :min-width="60" />
      </el-table>
      <el-pagination class="pagination"
                     @current-change="changeLoginRecordPage"
                     layout="prev, pager, next"
                     :page-size="loginRecordRequest.size"
                     :page-count="loginRecordPage.pages"
                     :current-page="loginRecordRequest.current"
                     style="float: left;"
      />
    </el-card>

    <el-card class="info-card">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Friends</span>
        </div>
      </template>
      <el-table :data="page.records" ref="table"
                v-loading="loading.friends"
                row-key="id"
                size="small"
                highlight-current-row
      >
        <el-table-column prop="loginName" label="Login Name">
          <template #default="scope">
            <el-link type="primary" :href="scope.row.id">{{ scope.row.loginName }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="Nick Name" />
        <el-table-column prop="type" label="Member Type">
          <template #default="scope">
            <el-tag v-if="scope.row.type === 'USER'" type="success" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'PARTNER'" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'GUEST'" type="info" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'ROBOT'" size="mini">{{ scope.row.type }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 'NORMAL'" type="success" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === 'FROZEN'" type="danger" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === null" type="info" size="mini">-</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="regTime" label="Reg Time" width="200px" />
        <el-table-column prop="lastLoginTime" label="Last Login" width="200px" />
      </el-table>
      <el-pagination class="pagination"
                     @current-change="changePage"
                     layout="prev, pager, next"
                     :page-size="request.size"
                     :page-count="page.pages"
                     :current-page="request.current"
                     style="float: left;"
      />
    </el-card>

    <el-card class="info-card">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Groups</span>
        </div>
      </template>
      <el-table :data="groupPage.records" ref="table"
                v-loading="loading.groups"
                row-key="id"
                size="small"
                highlight-current-row
                style="width: 100%"
      >
        <el-table-column prop="name" label="Group Name" :min-width="20">
          <template #default="scope">
            <router-link :to="`/group/details/${scope.row.id}`">
              <el-link type="primary">{{ scope.row.name }}</el-link>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status" :min-width="20">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 'NORMAL'" type="success" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === 'FROZEN'" type="danger" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === null" type="info" size="mini">-</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="role" label="Role" :min-width="20" />
        <el-table-column prop="memberCount" label="Members" :min-width="20" />
      </el-table>
      <el-pagination class="pagination"
                     @current-change="changeMemberGroupPage"
                     layout="prev, pager, next"
                     :page-size="groupRequest.size"
                     :page-count="groupPage.pages"
                     :current-page="groupRequest.current"
                     style="float: left;"
      />
    </el-card>

    <el-dialog :title="uiControl.dialogTitle" v-model="uiControl.dialogVisible" append-to-body width="580px">
      <el-form v-if="uiControl.dialogType === 'UPDATE_PASSWORD'" ref="updatePasswordForm" :model="passwordForm"
               :rules="passwordFormRules" :inline="true" size="small" label-width="150px"
      >
        <el-form-item label="Password" prop="password">
          <el-input v-model="passwordForm.password" type="password" style="width: 350px;" maxlength="11" />
        </el-form-item>
        <el-form-item label="Re-Enter Password" prop="reEnterPassword">
          <el-input v-model="passwordForm.reEnterPassword" type="password" style="width: 350px;" maxlength="11" />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="changePassword">confirm</el-button>
        </div>
      </el-form>

      <el-form v-if="uiControl.dialogType === 'UPDATE_LOGIN_NAME'" ref="updateLoginNameForm" :model="loginNameForm"
               :rules="loginNameFormRules" :inline="true" size="small" label-width="150px"
      >
        <el-form-item label="Login Name" prop="loginName">
          <el-input v-model="loginNameForm.loginName" style="width: 350px;" maxlength="11" />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="changeLoginName">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>

import { defineComponent, onMounted, reactive, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { required, size } from "../../../../../../utils/validate";
import {
  getMemberDetails,
  getMemberLoginRecords,
  freezeMember,
  unfreezeMember,
  getMemberFriends,
  getMemberGroups,
  updatePassword,
  updateLoginName
} from "../../../../../../api/member";
import moment from "moment";

export default defineComponent({
  props: {
    mbrId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const uiControl = reactive({
      dialogVisible: false,
      dialogTitle: "",
      dialogType: ""
    });

    const loading = reactive({
      accountInfo: false,
      friends: false,
      groups: false,
      loginRecord: false,
    });

    const memberDetail = reactive({
      id: 0,
      loginName: "",
      nickName: "",
      regTime: "",
      type: 0,
      lastLoginTime: "",
      status: "",
      realName: "",
      email: "",
      countryCode: "",
      telephone: "",
      regIp: "",
      lastLoginIp: "",
      lastLoginAddress: "",
      regAddress: ""
    });

    const updatePasswordForm = ref(null);
    const updateLoginNameForm = ref(null);

    const validatePassword = (rule, value, callback) => {
      if (value !== "" && passwordForm.reEnterPassword !== "") {
        updatePasswordForm.value.validateField("reEnterPassword");
      }
      callback();
    };

    const validateReEnterPassword = (rule, value, callback) => {
      if (value !== passwordForm.password) {
        callback(new Error("Two password does not match"))
      }
      callback();
    };

    const passwordForm = reactive({
      password: null,
      reEnterPassword: null
    });

    const loginNameForm = reactive({
      loginName: null,
    });

    const passwordFormRules = reactive({
      password: [required("Password is required"), size(6, 11, "Password has to be 6-11 characters"), {
        validator: validatePassword,
        trigger: "blur"
      }],
      reEnterPassword: [required("Please re-enter the password"), { validator: validateReEnterPassword, trigger: "blur" }]
    });

    const loginNameFormRules = reactive({
      password: [required("loginName is required"), size(6, 20, "Login name has to be 6-20 characters")]
    });

    const page = reactive({
      pages: 0,
      records: []
    });

    const loginRecordPage = reactive({
      pages: 0,
      records: []
    });

    const groupPage = reactive({
      pages: 0,
      records: []
    });

    const request = reactive({
      size: 10,
      current: 1
    });

    const endDate = new Date();
    const startDate = new Date(new Date().setDate(new Date().getDate() - 2));
    console.log(startDate)
    console.log(endDate)
    const defaultStartDate = convertDate(startDate);
    const defaultEndDate = convertDate(endDate);

    const loginRecordRequest = reactive({
      memberId: props.mbrId,
      range: [defaultStartDate, defaultEndDate],
      size: 5,
      current: 1
    });

    const groupRequest = reactive({
      memberId: props.mbrId,
      size: 10,
      current: 1
    });

    const freeze = (id) => {
      ElMessageBox.confirm(
        "Confirm freeze member?",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        await freezeMember(id);
        const data = await getMemberDetails(props.mbrId);
        Object.keys({ ...data.data }).forEach(detailField => {
          memberDetail[detailField] = data.data[detailField];
        });
        ElMessage({ message: "Freeze success", type: "success" });
      }).catch(() => {});
    };

    const unfreeze = (id) => {
      ElMessageBox.confirm(
        "Confirm Unfreeze member?",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        await unfreezeMember(id);
        const data = await getMemberDetails(props.mbrId);
        Object.keys({ ...data.data }).forEach(detailField => {
          memberDetail[detailField] = data.data[detailField];
        });
        ElMessage({ message: "Unfreeze success", type: "success" });
      }).catch(() => {});
    };

    const loadMemberFriends = async () => {
      loading.friends = true;
      const { data: ret } = await getMemberFriends(props.mbrId, request);
      page.pages = ret.pages;
      page.records = ret.records;
      loading.friends = false;
    };

    const loadMemberLoginRecords = async () => {
      loading.loginRecord = true;
      const { data: ret } = await getMemberLoginRecords(loginRecordRequest.memberId, loginRecordRequest.range[0], loginRecordRequest.range[1], loginRecordRequest.current, loginRecordRequest.size);
      loginRecordPage.pages = ret.pages;
      loginRecordPage.records = ret.records;
      loading.loginRecord = false;
    };

    const loadMemberGroups = async () => {
      loading.groups = true;
      const { data: ret } = await getMemberGroups(props.mbrId, groupRequest);
      groupPage.pages = ret.pages;
      groupPage.records = ret.records;
      loading.groups = false;
    };

    const changePage = (page) => {
      if (request.current >= 1) {
        request.current = page;
        loadMemberFriends();
      }
    };

    const changeLoginRecordPage = (page) => {
      if (loginRecordRequest.current >= 1) {
        loginRecordRequest.current = page;
        loadMemberLoginRecords();
      }
    };

    const changeMemberGroupPage = (page) => {
      if (groupRequest.current >= 1) {
        groupRequest.current = page;
        loadMemberGroups();
      }
    };

    const showDialog = (type) => {
      uiControl.dialogType = type;
      if (type === "UPDATE_PASSWORD") {
        if (updatePasswordForm.value) {
          updatePasswordForm.value.resetFields();
        }
        uiControl.dialogTitle = "Update Password";
      } else if (type === "UPDATE_LOGIN_NAME") {
        if (updateLoginNameForm.value) {
          updateLoginNameForm.value.resetFields();
        }
        uiControl.dialogTitle = "Update Login Name";
      }
      uiControl.dialogVisible = true;
    };

    const changePassword = () => {
      updatePasswordForm.value.validate(async (valid) => {
        if (valid) {
          await updatePassword(props.mbrId, passwordForm.password);
          uiControl.dialogVisible = false;
          ElMessage({ message: "Update Password Success", type: "success" });
        }
      });
    };

    const changeLoginName = () => {
      updateLoginNameForm.value.validate(async (valid) => {
        if (valid) {
          await updateLoginName(props.mbrId, loginNameForm.loginName);
          uiControl.dialogVisible = false;
          const data = await getMemberDetails(props.mbrId);
          Object.keys({ ...data.data }).forEach(detailField => {
            memberDetail[detailField] = data.data[detailField];
          });
          ElMessage({ message: "Update login name Success", type: "success" });
        }
      });
    };

    function convertDate(date) {
      return moment(date).format('yyyy-MM-DD');
    }

    const shortcuts = [
      {
        text: ('Today'),
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(moment(start).startOf('day').format('x'));
          return [start, end];
        }
      },
      {
        text: ('Yesterday'),
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(moment(start).subtract(1, 'days').startOf('day').format('x'));
          end.setTime(moment(end).subtract(1, 'days').endOf('day').format('x'));
          return [start, end];
        }
      },
      {
        text: ('This Week'),
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(moment(start).startOf('week').startOf('day').format('x'));
          return [start, end];
        }
      },
      {
        text: ('Last Week'),
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(moment(start).subtract(1, 'weeks').startOf('week').startOf('day').format('x'));
          end.setTime(moment(end).subtract(1, 'weeks').endOf('week').endOf('day').format('x'));
          return [start, end];
        }
      },
      {
        text: ('This Month'),
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(moment(start).startOf('month').startOf('day').format('x'));
          return [start, end];
        }
      },
      {
        text: ('Last Month'),
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(moment(start).subtract(1, 'months').startOf('month').startOf('day').format('x'));
          end.setTime(moment(end).subtract(1, 'months').endOf('month').endOf('day').format('x'));
          return [start, end];
        }
      }
    ];

    function disabledDate(time) {
      const disabled = new Date(2023, 6, 1);
      return time.getTime() > new Date().getTime() || time.getTime() < disabled.getTime();
    }

    onMounted(async () => {
      loading.accountInfo = true;
      const data = await getMemberDetails(props.mbrId);
      Object.keys({ ...data.data }).forEach(detailField => {
        memberDetail[detailField] = data.data[detailField];
      });
      loading.accountInfo = false;
      loadMemberLoginRecords();
      loadMemberFriends();
      loadMemberGroups();
    });

    return {
      uiControl,
      loading,
      memberDetail,
      page,
      loginRecordPage,
      groupPage,
      request,
      loginRecordRequest,
      groupRequest,
      updatePasswordForm,
      passwordForm,
      passwordFormRules,
      updateLoginNameForm,
      loginNameForm,
      loginNameFormRules,
      changePassword,
      changeLoginName,
      loadMemberFriends,
      loadMemberLoginRecords,
      loadMemberGroups,
      unfreeze,
      freeze,
      changePage,
      changeLoginRecordPage,
      changeMemberGroupPage,
      showDialog,
      disabledDate,
      shortcuts,
      startDate,
      endDate,
      defaultStartDate,
      defaultEndDate,
    };
  }
});

</script>
<style lang="scss" scoped>

.tables-container-wrap1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .info-card {
    margin-bottom: 10px;
  }
}

.box-card {
  ::v-deep(.el-card__body) {
    padding: 0;
  }
}

::v-deep {
  .el-tabs__content {
    padding: 0;
  }
}

::v-deep([class^="el-table"]) {
  .cell,
  .remove-padding {
    padding: 0 !important;
  }

  .remove-padding {
    .cell {
      display: flex;
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

</style>
