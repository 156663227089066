<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="Group" name="group-info">
      <GroupInfoTab :mbr-id="id" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { defineComponent, ref } from "vue";
import GroupInfoTab from "./tabs/group-info/index.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    GroupInfoTab
  },
  setup() {
    const activeName = ref('group-info')
    const router = useRouter()
    const {
      params: { id }
    } = router.currentRoute.value

    return {
      activeName,
      id
    }
  }
})
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.el-radio__label {
  display: inline !important;
}
</style>
