<template>
  <div @click="dialogVisible = true">
    <slot />
  </div>
  <el-dialog
    title="Change Password"
    v-model="dialogVisible"
    width="580px"
    custom-class="forget-pw-modal"
    append-to-body
  >
    <el-form
      :model="pwForgetModel"
      :rules="pwForgetRules"
      ref="pwForgetRef"
      size="small"
      label-width="162px"
    >
      <el-form-item
        v-if="requireOld"
        label="Current Password"
        prop="oldPW"
      >
        <el-input
          type="password"
          v-model="pwForgetModel.oldPassword"
          autocomplete="off"
          style="width: 350px;"
        />
      </el-form-item>
      <el-form-item
        label="New Password"
        prop="password"
      >
        <el-input
          type="password"
          v-model="pwForgetModel.password"
          autocomplete="off"
          style="width: 350px;"
        />
      </el-form-item>
      <el-form-item
        label="Confirm New Password"
        prop="cfmPW"
      >
        <el-input
          type="password"
          v-model="pwForgetModel.cfmPW"
          autocomplete="off"
          style="width: 350px;"
        />
      </el-form-item>
      <div class="forget-pw-btns">
        <el-button
          size="small"
          @click="dialogVisible = false"
        >
          Cancel
        </el-button>
        <el-button size="small" type="primary" @click="submitForm">Confirm</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  emits: [
    "submit"
  ],
  props: {
    requireOld: {
      type: Boolean
    }
  },
  setup(_, { emit }) {
    // TODO: when api ready hook up for buttons

    const pwForgetModel = reactive({
      oldPassword: "",
      password: "",
      cfmPW: ""
    });

    const pwForgetRef = ref();

    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password"));
      } else {
        if (pwForgetModel.cfmPW !== "") {
          // double check if it works
          pwForgetRef.value.validateField("cfmPW");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      // what is this rule param
      if (value === "") {
        callback(new Error("Please input the password again"));
      } else if (value !== pwForgetModel.password) {
        callback(new Error("Two inputs don't match!"));
      } else {
        callback();
      }
    };

    const validatePasswordLength = (rule, value, callback) => {
      if (value.length < 6 || value.length > 12) {
        callback(new Error("The password cannot be less than 6 digits or more than 12 digits"));
      } else {
        callback();
      }
    };

    const pwForgetRules = reactive({
      oldPassword: [], // do i wn async validator here?
      password: [{ validator: validatePass, trigger: "blur" }, { validator: validatePasswordLength, trigger: "blur" }],
      cfmPW: [{ validator: validatePass2, trigger: "blur" }, { validator: validatePasswordLength, trigger: "blur" }]
    });

    const dialogVisible = ref(false);

    const submitForm = () => {
      pwForgetRef.value.validate(async (valid) => {
        if (valid) {
          emit("submit", { ...pwForgetModel });
          dialogVisible.value = false;
        }
      });
    };

    return {
      dialogVisible,
      pwForgetModel,
      pwForgetRules,
      submitForm,
      pwForgetRef
    };
  }
});
</script>

<style lang="scss" scoped>
.forget-pw-modal[aria-modal] {
  max-width: 90%;
}

.forget-pw-btns {
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="scss" scoped></style>
