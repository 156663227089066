import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const loginRequest = (userInfo) => {
  return https().request("/auth/login", Method.POST, userInfo, ContentType.form);
};

export const logoutRequest = () => {
  return https().request("/auth?_method=DELETE", Method.POST);
};

export const userInfoRequest = () => {
  return https().request("/auth", Method.GET);
};

export const loadAuthMenu = () => {
  return https().request("/auth/menu", Method.GET);
};

export const updatePasswordRequest = (updateInfo) => {
  return https().request("/auth/password?_method=PUT", Method.POST, updateInfo, ContentType.form);
};

export const getUsers = (user) => {
  for (const key in user) {
    if (user[key] === null || user[key] === undefined || user[key] === "") {
      delete user[key];
    }
  }
  return https().request("/authority/user", Method.GET, user, ContentType.form);
};

export const createUser = (user) => {
  for (const key in user) {
    if (user[key] === null || user[key] === undefined || user[key] === "") {
      delete user[key];
    }
  }
  return https().request("/authority/user", Method.POST, user, ContentType.json);
};

export const updateUser = async (user) => {
  await https().request(`/authority/user/${user.id}?_method=PUT`, Method.POST, { roles: user.roles.join(","), nickName: user.nickName, userType: user.userType, status: user.status, dialogMax: user.dialogMax }, ContentType.form);
};

export const updateUserPassword = async (user) => {
  await https().request(`/authority/user/${user.id}/password?_method=PUT`, Method.POST, { password: user.password }, ContentType.form);
};

export const updateUserState = async (id, state) => {
  await https().request(`/authority/user/${id}/state?_method=PUT`, Method.POST, { state: state }, ContentType.form);
};

export const deleteUser = async (ids) => {
  await https().request(`/authority/user?_method=DELETE`, Method.POST, { ids: ids.join(",") }, ContentType.form);
};

export const updateUserDialogMax = async (user) => {
  await https().request(`/authority/user/${user.id}/dialogMax?_method=PUT`, Method.POST, { dialogMax: user.dialogMax }, ContentType.form);
};

export const getUsersProfilePic = (user) => {
  for (const key in user) {
    if (user[key] === null || user[key] === undefined || user[key] === "") {
      delete user[key];
    }
  }
  return https().request("/authority/user/profile-pic", Method.GET, user, ContentType.form);
};

export const updateUserProfilePicture = async (user) => {
  await https().request(`/authority/user/${user.id}/updateHeadIcon?_method=PUT`, Method.POST, { head: user.head, nickName: user.nickName }, ContentType.form);
};
