import { SOCKET_ONCLOSE, SOCKET_ONERROR, SOCKET_ONMESSAGE, SOCKET_ONOPEN } from "../store/modules/socket/mutation-types";

export class Socket {
  constructor(store) {
    this.store = store
  }

  connection() {
    this.webSocket = new WebSocket(`${process.env.VUE_APP_SOCKET}/ws/notice`);
    this.webSocket.onmessage = message => this.store.commit(SOCKET_ONMESSAGE, message);
    this.webSocket.onopen = ev => this.store.commit(SOCKET_ONOPEN, ev);
    this.webSocket.onerror = ev => this.store.commit(SOCKET_ONERROR, ev);
    this.webSocket.onclose = ev => this.store.commit(SOCKET_ONCLOSE, ev);
  }
}
