import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "@/assets/iconfont/iconfont.css";
import "@/styles/index.scss";
import 'element-plus/theme-chalk/index.css'
import "normalize.css";
import * as directives from "@/directives";
import "@/permission";
import "@/icons";
import SvgIcon from "@/components/SvgIcon/index.vue";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import { Socket } from "./utils/socket";
import * as echarts from 'echarts'
import { cacher } from './utils/cache'

const socket = new Socket(store);
const app = createApp(App);
app.config.globalProperties.$socket = socket
app.use(ElementPlus)
app.use(echarts);
app.use(cacher);
app.component("SvgIcon", SvgIcon);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// console.log(process.env.VUE_APP_BASE_API);

Object.keys(directives).forEach(key => {
  app.directive(key, directives[key]);
});

app.use(store).use(router).mount("#app");
