import { ContentType, Method } from "axios-mapper";
import https from "../utils/https";

export const getGroups = (groups) => {
  return https().request("/group", Method.GET, groups, ContentType.form);
};

export const getGroupDetails = (id) => {
  return https().request(`/group/detail/${id}`, Method.GET, {}, ContentType.form);
};

export const freezeGroup = (id) => {
  return https().request(`/group/${id}/freeze?_method=PUT`, Method.PUT, { id: id });
};

export const unfreezeGroup = (id) => {
  return https().request(`/group/${id}/unfreeze?_method=PUT`, Method.PUT, { id: id });
};

export const getGroupMembers = (id, query) => {
  return https().request(`/group/members/${id}`, Method.GET, query, ContentType.form);
};

export const updateGroupInviteUsers = (id, inviteUsers) => {
  return https().request(`/group/${id}/update-invite-users?_method=PUT`, Method.PUT, { inviteUsers: inviteUsers }, ContentType.form);
};

export const updateGroupSpeakMode = (id, speakMode) => {
  return https().request(`/group/${id}/update-speak-mode?_method=PUT`, Method.PUT, { speakMode: speakMode }, ContentType.form);
};

export const updateGroupPrivatelyChat = (id, privatelyChat) => {
  return https().request(`/group/${id}/update-privately-chat?_method=PUT`, Method.PUT, { privatelyChat: privatelyChat }, ContentType.form);
};

export const updateGroupAddUsers = (id, addUsers) => {
  return https().request(`/group/${id}/update-add-users?_method=PUT`, Method.PUT, { addUsers: addUsers }, ContentType.form);
};

export const kickoutGroupMember = (groupId, memberId) => {
  return https().request(`/group/${groupId}/kickout`, Method.POST, { memberId: memberId }, ContentType.form);
};

export const updateGroupMemberRole = (groupId, memberId, role) => {
  return https().request(`/group/${groupId}/update-role?_method=PUT`, Method.POST, { memberId: memberId, role: role }, ContentType.form);
};
