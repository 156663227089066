<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-input
          v-model="request.name"
          size="small"
          style="width: 200px"
          placeholder="user name"
        />
        <el-select
          clearable
          v-model="request.enable"
          size="small"
          placeholder="user state"
          class="filter-item"
          style="width: 120px; margin-left: 5px"
        >
          <el-option
            v-for="item in uiControl.userSate"
            :key="item.key"
            :label="item.displayName"
            :value="item.value"
          />
        </el-select>
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadUser"
        >
          Search
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="warning"
          @click="resetQuery()"
        >
          reset
        </el-button>
      </div>
      <div class="btn-group">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          v-permission="['sys:user:create']"
          @click="showDialog('CREATE')"
        >
          Add
        </el-button>
        <el-button
          icon="el-icon-edit"
          size="mini"
          type="success"
          v-permission="['sys:user:update']"
          @click="showEdit()"
          :disabled="uiControl.editBtn"
        >
          Edit
        </el-button>
        <el-button
          icon="el-icon-remove"
          size="mini"
          type="danger"
          v-permission="['sys:user:delete']"
          @click="removeUser()"
          :disabled="uiControl.removeBtn"
        >
          Delete
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="580px"
    >
      <el-form
        ref="userForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <el-form-item label="User Name" prop="loginName">
          <el-input
            :disabled="uiControl.dialogType !== 'CREATE'"
            v-model="form.loginName"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          label="Nick Name"
          prop="nickName"
          v-if="
            uiControl.dialogType === 'CREATE' || uiControl.dialogType === 'EDIT'
          "
        >
          <el-input v-model="form.nickName" style="width: 350px" />
        </el-form-item>
        <el-form-item
          v-if="
            uiControl.dialogType === 'CREATE' ||
              uiControl.dialogType === 'CHANGE_PASSWORD'
          "
          label="Password"
          prop="password"
        >
          <el-input
            type="password"
            v-model="form.password"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          v-if="
            uiControl.dialogType === 'CREATE' ||
              uiControl.dialogType === 'CHANGE_PASSWORD'
          "
          label="Confirm Password"
          prop="confirm"
        >
          <el-input
            type="password"
            v-model="form.confirm"
            style="width: 350px"
          />
        </el-form-item>
        <el-form-item
          label="User Type"
          prop="userType"
          v-if="
            uiControl.dialogType === 'CREATE' || uiControl.dialogType === 'EDIT'
          "
        >
          <el-select
            v-model="form.userType"
            size="small"
            class="filter-item"
            style="width: 350px"
            default-first-option
          >
            <el-option
              v-for="item in userTypeList"
              :key="item.name"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="
            uiControl.dialogType === 'CREATE' || uiControl.dialogType === 'EDIT'
          "
          label="Role"
          prop="roles"
        >
          <el-select
            v-model="form.roles"
            multiple
            placeholder="Please choose"
            style="width: 350px"
            filterable
            @focus="loadRoles()"
            :disabled="uiControl.rolesSelect"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!--        <el-form-item-->
        <!--          label="Dialog Max"-->
        <!--          prop="dialogMax"-->
        <!--          v-if="uiControl.dialogType !== 'CHANGE_PASSWORD'"-->
        <!--        >-->
        <!--          <el-input-number-->
        <!--            type="number"-->
        <!--            v-model.number="form.dialogMax"-->
        <!--            :min="0"-->
        <!--            style="width: 350px"-->
        <!--            controls-position="right"-->
        <!--          />-->
        <!--        </el-form-item>-->
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-table
      :data="page.records"
      ref="table"
      row-key="id"
      size="mini"
      highlight-current-row
      @selection-change="handleSelectionChange"
      :cell-class-name="cellGrayWhite"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="loginName" label="User Name" width="200">
        <template #default="scope">
          {{ scope.row.loginName }}
          <el-tag
            size="small"
            v-if="scope.row.loginName === LOGIN_USER_NAME"
            style="margin-left: 5px"
          >
            it's you
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="nickName" label="Nick Name" />
      <el-table-column prop="status" label="State" width="100">
        <template #default="scope">
          <el-switch
            :disabled="!hasPermission(['sys:user:update:state'])"
            v-model="scope.row.status"
            active-color="#409EFF"
            inactive-color="#F56C6C"
            @change="changeUserState(scope.row.id, scope.row.status)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="Create Time" />
      <el-table-column prop="createBy" label="Create By" />
      <el-table-column
        label="operate"
        width="280"
        align="right"
        v-if="
          hasPermission(['sys:user:update:password']) ||
            hasPermission(['sys:user:update']) ||
            hasPermission(['sys:user:delete']) ||
            hasPermission(['sys:user:update:dialogmax'])
        "
      >
        <template #default="scope">
          <el-button
            icon="el-icon-lock"
            size="mini"
            type="info"
            v-permission="['sys:user:update:password']"
            @click="showChangePassword(scope.row)"
          >
            Update Password
          </el-button>
          <el-button
            icon="el-icon-edit"
            size="mini"
            type="success"
            v-permission="['sys:user:update']"
            @click="showEdit(scope.row)"
          />
          <el-button
            icon="el-icon-remove"
            size="mini"
            type="danger"
            v-permission="['sys:user:delete']"
            @click="removeUser(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { required, size } from '../../../utils/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  createUser,
  deleteUser,
  getUsers,
  updateUser,
  updateUserPassword,
  updateUserState,
} from '../../../api/user'
import { getSimpleRoles } from '../../../api/roles'
import { useStore } from '../../../store'
import {
  MANAGER,
  TENANT,
} from '../../../store/modules/user/action-types'
import { hasPermission } from '../../../utils/util'
import { cellGrayWhite } from '@/utils/style'

const store = useStore()
const LOGIN_USER_TYPE = computed(() => store.state.user.userType)
const LOGIN_USER_NAME = computed(() => store.state.user.name)
const userTypeList = computed(() => {
  return [MANAGER, TENANT]
})

const userForm = ref(null)
const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
  editBtn: true,
  removeBtn: true,
  rolesSelect: true,
  userSate: [
    { key: 1, displayName: 'active', value: true },
    { key: 2, displayName: 'disable', value: false },
  ],
})
const page = reactive({
  pages: 0,
  records: [],
})
const request = reactive({
  size: 30,
  current: 1,
  name: null,
  enable: null,
})
const options = ref([])

const form = reactive({
  id: null,
  loginName: null,
  password: null,
  nickName: null,
  confirm: null,
  roles: null,
  userType:
    LOGIN_USER_TYPE.value === TENANT.value ? LOGIN_USER_TYPE.value : null,
  status: true,
  dialogMax: null,
})

const validateconfirm = (rule, value, callback) => {
  if (value !== form.password) {
    callback(new Error('Two passwords are different'))
  } else {
    callback()
  }
}
const formRules = reactive({
  loginName: [
    required('username is require'),
    size(6, 11, 'username length is 6 to 11'),
  ],
  password: [
    required('password is require'),
    size(6, 11, 'password length is 6 to 11'),
  ],
  confirm: [
    required('confirm password is require'),
    size(6, 11, 'confirm password length is 6 to 11'),
    {
      validator: validateconfirm,
      trigger: 'blur',
    },
  ],
  roles: [required('roles is require')],
  userType: [required('User Type is require')],
  dialogMax: [required('Dialog Max is require')],
})

let chooseUser = []

function resetQuery() {
  request.name = null
  request.enable = null
}

function handleSelectionChange(val) {
  chooseUser = val
  if (chooseUser.length === 0) {
    uiControl.editBtn = true
    uiControl.removeBtn = true
  } else if (chooseUser.length === 1) {
    uiControl.editBtn = false
    uiControl.removeBtn = false
  } else {
    uiControl.editBtn = true
    uiControl.removeBtn = false
  }
}

async function loadUser() {
  const { data: ret } = await getUsers(request)
  page.pages = ret.pages
  page.records = ret.records
}

async function loadRoles() {
  const { data: roles } = await getSimpleRoles()
  options.value = roles
}

function changePage(page) {
  request.current = page
  loadUser()
}

function showDialog(type) {
  if (type === 'CREATE') {
    if (userForm.value) {
      userForm.value.resetFields()
    }
    form.id = null
    form.roles = null
    form.userType =
      LOGIN_USER_TYPE.value === TENANT.value ? LOGIN_USER_TYPE.value : null
    uiControl.dialogTitle = 'Add User'
  } else if (type === 'EDIT') {
    uiControl.dialogTitle = 'Edit User'
  } else {
    uiControl.dialogTitle = 'Update Password'
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
}

function showEdit(user) {
  if (!user) {
    user = chooseUser[0]
  }
  for (const key in user) {
    if (Object.keys(form).find(k => k === key)) {
      form[key] = user[key]
    }
  }
  showDialog('EDIT')
}

function showChangePassword(user) {
  showDialog('CHANGE_PASSWORD')
  nextTick(() => {
    form.id = user.id
    form.loginName = user.loginName
  })
}

/**
 * 新增用户
 */
function create() {
  userForm.value.validate(async valid => {
    if (valid) {
      await createUser(form)
      uiControl.dialogVisible = false
      await loadUser()
      ElMessage({ message: 'Add Success', type: 'success' })
    }
  })
}

/**
 * 编辑用户
 */
function edit() {
  userForm.value.validate(async valid => {
    if (valid) {
      await updateUser(form)
      uiControl.dialogVisible = false
      await loadUser()
      ElMessage({ message: 'Edit Success', type: 'success' })
    }
  })
}

function updatePassword() {
  userForm.value.validate(async valid => {
    if (valid) {
      await updateUserPassword(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Password Success', type: 'success' })
    }
  })
}

async function changeUserState(id, state) {
  await updateUserState(id, state)
}

async function removeUser(user) {
  ElMessageBox.confirm(
    'Confirm that you want to delete these data, the operation cannot be undone',
    {
      confirmButtonText: 'confirm',
      cancelButtonText: 'cancel',
      type: 'warning',
    }
  ).then(async () => {
    if (user) {
      await deleteUser([user.id])
    } else {
      await deleteUser(chooseUser.map(u => u.id))
    }
    await loadUser()
    ElMessage({ message: 'Delete Success', type: 'success' })
  })
}

function submit() {
  if (uiControl.dialogType === 'CREATE') {
    create()
  } else if (uiControl.dialogType === 'EDIT') {
    edit()
  } else {
    updatePassword()
  }
}

watch(
)
watch(
  () => form.userType,
  async () => {
    uiControl.rolesSelect = false;
  },
  {
    immediate: true,
    deep: true
  }
)

onMounted(() => {
  loadUser()
  loadRoles()
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}
</style>
