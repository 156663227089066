<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="Member" name="member-info">
      <MemberInfoTab :mbr-id="id" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { defineComponent, ref } from "vue";
import MemberInfoTab from "./tabs/member-info/index.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    MemberInfoTab
  },
  setup() {
    console.log("setting up");
    const activeName = ref('member-info')
    const router = useRouter()
    const {
      params: { id }
    } = router.currentRoute.value

    return {
      activeName,
      id
    }
  }
})
</script>
