<template>
  <div>
    <div class="header-container">
      <div class="search">
        <el-input v-model="request.loginName" size="small" style="width: 200px;" placeholder="login name" />
        <el-select
          clearable
          v-model="request.type"
          size="small"
          placeholder="Member Type"
          class="filter-item"
          style="width: 120px;margin-left: 5px"
        >
          <el-option
            v-for="item in uiControl.memberType"
            :key="item.key"
            :label="item.displayName"
            :value="item.value"
          />
        </el-select>
        <el-select
          clearable
          v-model="request.status"
          size="small"
          placeholder="Member Status"
          class="filter-item"
          style="width: 120px;margin-left: 5px"
        >
          <el-option
            v-for="item in uiControl.memberState"
            :key="item.key"
            :label="item.displayName"
            :value="item.value"
          />
        </el-select>
        <el-button style="margin-left: 20px" icon="el-icon-search" size="mini" type="success" @click="loadMembers()">
          Search
        </el-button>
        <el-button size="mini" type="warning" @click="resetQuery()">Reset</el-button>
      </div>
    </div>
    <el-card class="box-card" shadow="never" style="margin-top: 40px">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Member List</span>
        </div>
      </template>
      <el-table :data="page.records" ref="table"
                v-loading="page.loading"
                row-key="id"
                size="mini"
                :resizable="true"
                highlight-current-row
                @selection-change="handleSelectionChange"
      >
        <el-table-column prop="loginName" label="Login Name">
          <template #default="scope" v-if="hasPermission(['sys:member:detail'])">
            <router-link :to="`details/${scope.row.id}`">
              <el-link>{{ scope.row.loginName }}</el-link>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="Member Type">
          <template #default="scope">
            <el-tag v-if="scope.row.type === 'USER'" type="success" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'PARTNER'" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'GUEST'" type="info" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'ROBOT'" size="mini">{{ scope.row.type }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 'NORMAL'" type="success" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === 'FROZEN'" type="danger" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === null" type="info" size="mini">-</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="regTime" label="Register Time">
          <template #default="scope">
            <span v-if="scope.row.regTime === null">-</span>
            <span v-if="scope.row.regTime !== null" v-formatter="{data: scope.row.regTime,formatter: 'YYYY/MM/DD HH:mm:ss',type: 'date'}" />
          </template>
        </el-table-column>
        <el-table-column prop="lastLoginTime" label="Last Login">
          <template #default="scope">
            <span v-if="scope.row.lastLoginTime === null">-</span>
            <span v-if="scope.row.lastLoginTime !== null" v-formatter="{data: scope.row.lastLoginTime,formatter: 'YYYY/MM/DD HH:mm:ss',type: 'date'}" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination"
                     @current-change="changePage"
                     layout="prev, pager, next"
                     :page-size="request.size"
                     :page-count="page.pages"
                     :current-page="request.current"
      />
    </el-card>
  </div>
</template>

<script setup>

import { reactive, ref } from "vue";
import { getMembers } from "../../../../api/member";
import { hasPermission } from '../../../../utils/util'

const table = ref(null);

const uiControl = reactive({
  dialogVisible: false,
  memberState: [
    { key: 1, displayName: "All Status", value: null },
    { key: 2, displayName: "Normal", value: "NORMAL" },
    { key: 3, displayName: "Frozen", value: "FROZEN" }
  ],
  memberType: [
    { key: 1, displayName: "All Types", value: null },
    { key: 2, displayName: "User", value: "USER" },
    { key: 3, displayName: "Partner", value: "PARTNER" },
    { key: 4, displayName: "Guest", value: "GUEST" },
    { key: 5, displayName: "Robot", value: "ROBOT" }
  ],
});

const page = reactive({
  pages: 0,
  records: [],
  loading: false
});

const request = reactive({
  size: 30,
  current: 1,
  loginName: null,
  type: null,
  status: null,
});

function resetQuery() {
  request.loginName = null;
  request.type = null;
  request.status = null;
  uiControl.searchDialogVisible = false;
}

function changePage(page) {
  if (request.current >= 1) {
    request.current = page;
    loadMembers();
  }
}

function checkQuery() {
  const requestCopy = { ...request };
  const query = {};
  Object.entries(requestCopy).forEach(([key, value]) => {
    if (value) {
      query[key] = value;
    }
  });
  return query;
}

async function loadMembers() {
  page.loading = true;
  uiControl.searchDialogVisible = false;
  const query = checkQuery();
  const { data: ret } = await getMembers(query);
  console.log(ret)
  page.pages = ret.pages;
  page.records = ret.records;
  page.loading = false;
  table.value.clearSelection();
}

</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-progress--line {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 430px;
}

.el-result {
  padding: 0;
}
</style>
