import https from "@/utils/https";
import { Method } from "axios-mapper";

export const statsType = {
  DAY: "DAY", HOUR: "HOUR"
}

export const getMemberLoginByTime = (startDate, endDate, statsType) => {
  return https().request("/stats/member-login/by-time", Method.GET, { startDate: startDate, endDate: endDate, statisticsType: statsType });
};
