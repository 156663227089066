import { SOCKET_ONCLOSE, SOCKET_ONERROR, SOCKET_ONMESSAGE, SOCKET_ONOPEN } from "./mutation-types";

export const mutations = {
  [SOCKET_ONOPEN](state, event) {
    console.log(`${event.currentTarget.url} 连接成功`);
    state.isConnected = true
  },
  [SOCKET_ONCLOSE](state, event) {
    console.log(`${event.currentTarget.url} 连接关闭`);
    state.isConnected = false
  },
  [SOCKET_ONERROR](state, event) {
    console.error(`${event.currentTarget.url} 连接异常`);
  },
  [SOCKET_ONMESSAGE](state, message) {
    console.log(`${event.currentTarget.url} 接收消息${message}`);
    state.event = message;
  },
};
