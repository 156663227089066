<template>
  <div class="roles-main">
    <div class="header-container">
      <el-button
        icon="el-icon-plus"
        size="mini"
        type="primary"
        @click="showCreatePartner()"
      >
        Add
      </el-button>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="450px"
    >
      <el-form
        ref="partnerForm"
        :rules="formRules"
        :model="form"
        :inline="true"
        size="medium"
        label-width="150px"
      >
        <el-form-item label="Partner" prop="name">
          <el-input
            :disabled="true"
            v-model="form.name"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="Status">
          <el-select
            v-model="form.status"
            placeholder="Status"
            style="width: 200px"
          >
            <el-option
              v-for="item in uiControl.partnerStatus"
              :key="item.id"
              :label="item.displayName"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="submit">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="uiControl.createDialogTitle"
      v-model="uiControl.createDialogVisible"
      append-to-body
      width="450px"
    >
      <el-form
        ref="createPartnerForm"
        :rules="createFormRules"
        :model="createForm"
        :inline="true"
        size="medium"
        label-width="150px"
      >
        <el-form-item label="Partner Name" prop="name">
          <el-input
            v-model="createForm.name"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="Partner Code" prop="code">
          <el-input
            v-model="createForm.code"
            style="width: 200px"
          />
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="uiControl.createDialogVisible = false">cancel</el-button>
          <el-button type="primary" @click="addPartner">confirm</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-table
      :data="records.list"
      ref="table"
      row-key="id"
      size="mini"
      class="no-highlight"
    >
      <el-table-column prop="name" label="Partner Name" />
      <el-table-column prop="code" label="Partner Code" />
      <el-table-column prop="status" label="Partner Status">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 'OPEN'" type="success" size="mini">{{ scope.row.status }}</el-tag>
          <el-tag v-if="scope.row.status === 'CLOSE'" type="danger" size="mini">{{ scope.row.status }}</el-tag>
          <el-tag v-if="scope.row.status === 'TEST'" type="info" size="mini">{{ scope.row.status }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="operate" width="200">
        <template #default="scope">
          <el-button
            size="mini"
            type="success"
            icon="el-icon/>"
            @click="showEditStatus(scope.row)"
          >
            Update Status
          </el-button>
          <el-button
            icon="el-icon-remove"
            size="mini"
            type="danger"
            @click="removePartner(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { nextTick, onMounted, reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getPartners, updatePartnerStatus, createPartner, deletePartner } from '../../../api/partner'
import { required } from '../../../utils/validate'

const records = reactive({
  list: [],
})

const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  createDialogVisible: false,
  createDialogTitle: '',
  partnerStatus: [
    { key: 1, displayName: "Open", value: "OPEN" },
    { key: 2, displayName: "Close", value: "CLOSE" },
    { key: 3, displayName: "Test", value: "TEST" }
  ],
})

const partnerForm = ref(null)
const createPartnerForm = ref(null)

const form = reactive({
  id: null,
  name: null,
  status: null,
})

const createForm = reactive({
  code: null,
  name: null,
})

const formRules = reactive({
  id: [required('Partner id is required')],
  status: [required('Status is required')],
})

const createFormRules = reactive({
  code: [required('Code is required')],
  name: [required('Name is required')],
})

function submit() {
  editPartnerStatus()
}

async function loadPartners() {
  const { data: ret } = await getPartners()
  records.list = ret
}

function showEditStatus(partner) {
  uiControl.dialogTitle = 'Update Partner Status'
  uiControl.dialogVisible = true
  nextTick(() => {
    form.id = partner.id
    form.name = partner.name
    form.status = partner.status
  })
}

function showCreatePartner() {
  uiControl.createDialogTitle = 'Create Partner'
  uiControl.createDialogVisible = true
}

function editPartnerStatus() {
  partnerForm.value.validate(async valid => {
    if (valid) {
      await updatePartnerStatus(form)
      uiControl.dialogVisible = false
      ElMessage({ message: 'Update Partner Success', type: 'success' })
    }
    loadPartners()
  })
}

function addPartner() {
  createPartnerForm.value.validate(async valid => {
    if (valid) {
      await createPartner(createForm)
      uiControl.createDialogVisible = false
      ElMessage({ message: 'Create Partner Success', type: 'success' })
    }
    createForm.code = null
    createForm.name = null
    loadPartners()
  })
}

async function removePartner(partner) {
  ElMessageBox.confirm(
    'Confirm that you want to delete these data, the action cannot be undone',
    {
      confirmButtonText: 'confirm',
      cancelButtonText: 'cancel',
      type: 'warning',
    }
  ).then(async () => {
    if (partner) {
      await deletePartner(partner.id)
    }
    await loadPartners()
    ElMessage({ message: 'Delete Success', type: 'success' })
  })
}

onMounted(async() => {
  await loadPartners()
})

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 38px;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}

.icon-body {
  width: 100%;

  .headicon-box {
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    div {
      cursor: pointer;

    }
  }
}

.headicon-img {
  width: 60px;
  display: block;
}

:deep(.el-radio-button--small .el-radio-button__inner) {
  padding: 0;
}

:deep(.el-radio-button--small) {
  padding: 0;
  img{

    filter: grayscale(.7);
  }

  &.is-active {
    img {
      filter: grayscale(0);
    }
  }
}
</style>
