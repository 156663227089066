<template>
  <div>
    <div class="header-container">
      <div class="search">
        <el-input v-model="request.name" size="small" style="width: 200px;" placeholder="Name" />
        <el-button style="margin-left: 20px" icon="el-icon-search" size="mini" type="success" @click="loadGroups()">
          Search
        </el-button>
        <el-button size="mini" type="warning" @click="resetQuery()">Reset</el-button>
      </div>
    </div>
    <el-card class="box-card" shadow="never" style="margin-top: 40px">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Group List</span>
        </div>
      </template>
      <el-table :data="page.records" ref="table"
                v-loading="page.loading"
                row-key="id"
                size="mini"
                :resizable="true"
                highlight-current-row
                @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column prop="name" label="Name">
          <template #default="scope" v-if="hasPermission(['sys:group:detail'])">
            <router-link :to="`details/${scope.row.id}`">
              <el-link>{{ scope.row.name }}</el-link>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="speakMode" label="Speak Mode">
          <template #default="scope">
            <el-tag v-if="scope.row.speakMode === 'NORMAL'" type="success" size="mini">{{ scope.row.speakMode }}</el-tag>
            <el-tag v-else type="danger" size="mini">{{ scope.row.speakMode }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="addUsers" label="Add Users">
          <template #default="scope">
            <el-tag v-if="scope.row.inviteUsers === 'ALL'" type="success" size="mini">{{ scope.row.addUsers }}</el-tag>
            <el-tag v-else type="danger" size="mini">{{ scope.row.addUsers }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="shareQrcode" label="Share QR">
          <template #default="scope">
            <el-tag v-if="scope.row.shareQrcode === 'NORMAL'" type="success" size="mini">{{ scope.row.shareQrcode }}</el-tag>
            <el-tag v-else type="danger" size="mini">{{ scope.row.shareQrcode }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 'NORMAL'" type="success" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === 'FROZEN'" type="danger" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === null" type="info" size="mini">-</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pagination"
                     @current-change="changePage"
                     layout="prev, pager, next"
                     :page-size="request.size"
                     :page-count="page.pages"
                     :current-page="request.current"
      />
    </el-card>
  </div>
</template>

<script setup>

import { reactive, ref } from "vue";
import { getGroups } from "../../../../api/group";
import { hasPermission } from '../../../../utils/util'

const table = ref(null);

const page = reactive({
  pages: 0,
  records: [],
  loading: false
});

const request = reactive({
  size: 30,
  current: 1,
  loginName: null,
  type: null,
  status: null,
});

function resetQuery() {
  request.loginName = null;
  request.type = null;
  request.status = null;
}

function changePage(page) {
  if (request.current >= 1) {
    request.current = page;
    loadGroups();
  }
}

function checkQuery() {
  const requestCopy = { ...request };
  const query = {};
  Object.entries(requestCopy).forEach(([key, value]) => {
    if (value) {
      query[key] = value;
    }
  });
  return query;
}

async function loadGroups() {
  page.loading = true;
  const query = checkQuery();
  const { data: ret } = await getGroups(query);
  page.pages = ret.pages;
  page.records = ret.records;
  page.loading = false;
  table.value.clearSelection();
}

</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-progress--line {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 430px;
}

.el-result {
  padding: 0;
}
</style>
