import { ContentType, Method } from "axios-mapper";
import https from "../utils/https";

export const getPartners = () => {
  return https().request("/partner", Method.GET, undefined, ContentType.form);
};

export const updatePartnerStatus = async (partner) => {
  await https().request(`/partner/${partner.id}?_method=PUT`, Method.POST, {
    status: partner.status
  }, ContentType.form);
};

export const createPartner = (partner) => {
  return https().request(`/partner`, Method.POST, partner, ContentType.form);
};

export const deletePartner = (id) => {
  return https().request(`/partner/${id}?_method=DELETE`, Method.POST);
};
