import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getRealTimeServerStats = () => {
  return https().request("/server/real-time-stats", Method.GET);
};

export const runServer = (serverId) => {
  return https().request(`/server/${serverId}/run?_method=PUT`, Method.POST);
};

export const pauseServer = (serverId) => {
  return https().request(`/server/${serverId}/pause?_method=PUT`, Method.POST);
};

export const updateOuterUrl = (id, url) => {
  return https().request(`/server/${id}/update-outer-url?_method=PUT`, Method.POST, { outerUrl: url }, ContentType.form);
};

export const updateOuterUrlApp = (id, url) => {
  return https().request(`/server/${id}/update-outer-url-app?_method=PUT`, Method.POST, { outerUrl: url }, ContentType.form);
};
